<template>
    <custom-modal
        width="90%"
        title="查看申请资料"
        :footer="null"
        :visible="visible"
        @on-close="() => $emit('on-close')"
    >
        <modal-content
            :handling-info-tpl="handlingInfoTpl"
            :special-bank-account-info="specialBankAccountInfo"
        />
    </custom-modal>
</template>
<script>
import CustomModal from '@components/CustomModal'
import ModalContent from './Content'

export default {
    name: 'LookOverApplyInfoModal',
    components: {
        CustomModal,
        ModalContent
    },
    props: {
        visible: Boolean,
        handlingInfoTpl: Object,
        specialBankAccountInfo: Object
    }
}
</script>
