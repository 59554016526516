<template>
    <div class="margin-payment-detail">
        <!-- 项目信息 -->
        <project-info-panel :workflow-apply-record-id="workflowApplyRecordId" />

        <!-- 保证金专户信息 -->
        <special-account-info-panel class="has-margin-top" :data-source="specialBankAccountInfo" />

        <!-- 办理资料 -->
        <a-card class="has-margin-top">
            <template #title>
                <span style="font-size: 14px">办理资料</span>
            </template>
            <file-upload-description-list v-bind="fileUploadListProps" />
        </a-card>
    </div>
</template>
<script>
import { Card as ACard } from 'ant-design-vue'
import FileUploadDescriptionList from '@weights/FileUploadDescriptionList'
import ProjectInfoPanel from '@weights/marginManagement/MarginPaymentDetail/ProjectInfoPanel'

import SpecialAccountInfoPanel from '../../SpecialAccountInfoPanel'

export default {
    name: 'ModalContent',
    components: {
        ACard,
        ProjectInfoPanel,
        SpecialAccountInfoPanel,
        FileUploadDescriptionList
    },
    props: {
        specialBankAccountInfo: {
            type: Object,
            default: () => ({})
        },
        handlingInfoTpl: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        workflowApplyRecordId() {
            return this.$route.query.workflowApplyRecordId
        },
        fileUploadListProps() {
            return Object.freeze({
                colon: 0,
                align: 1,
                ...this.handlingInfoTpl.props || {}
            })
        }
    }
}
</script>
