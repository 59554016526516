import axios from '@service'
import { REFRESH_BALANCE } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

// 刷新余额
export default async function refreshCurrentService(payload = {}, config = {}) {
    const param = {
        ...REFRESH_BALANCE,
        ...config,
        params: payload
    }

    const { data } = await axios(param)

    if (data.code === HTTP_ERR_SUCCESS) {
        return data.result || {}
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
