<template>
    <information-panel :columns="columns" :data-source="dataSource">
        <template #title>
            <span style="font-size: 14px">保证金专户信息</span>
        </template>

        <template #currentBalance="val">
            {{ val | amountFilter }}

            <a-button
                type="link"
                :loading="loading"
                style="height: auto;"
                @click.stop="() => handleRefresh(id)"
            >
                刷新
            </a-button>
        </template>
    </information-panel>
</template>
<script>
import { Button as AButton } from 'ant-design-vue'
import InformationPanel from '@components/InformationPanel'

import { amountFilter } from '@utils/filter'

import { columns } from './config'

import refreshCurrentService from '@service/marginManagement/marginPaymentDetail/refreshCurrentService'

export default {
    name: 'SpecialAccountInfoPanel',
    components: {
        AButton,
        InformationPanel
    },
    filters: {
        amountFilter
    },
    props: {
        dataSource: Object
    },
    data() {
        return {
            columns,

            loading: false
        }
    },
    computed: {
        id() {
            return this.dataSource.id
        }
    },
    methods: {
        async handleRefresh(id) {
            try {
                this.loading = true

                // eslint-disable-next-line
                await refreshCurrentService({ id })
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
                this.$emit('on-refresh')
            }
        }
    }
}
</script>
