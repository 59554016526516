// import renderPreviewFile from '@weights/customRender/renderPreviewFile'

import { empty as emptyFilter } from '@utils'

export const columns = [
    {
        dataIndex: 'bankCodeText',
        label: '开户行',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'bankFullNameCodeText',
        label: '开户行全称',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'accountName',
        label: '账户名称',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'accountNumber',
        label: '账户号码',
        customRender: val => emptyFilter(val)
    }
    // {
    //     dataIndex: 'connectionStatusText',
    //     label: '银行直接状态',
    //     customRender: val => emptyFilter(val)
    // },
    // {
    //     dataIndex: 'currentBalance',
    //     label: '当前余额',
    //     scopedSlots: {
    //         customRender: 'currentBalance'
    //     }
    // },
    // {
    //     dataIndex: 'marginAccountCredentials',
    //     label: '开户凭证',
    //     customRender: renderPreviewFile
    // }
]

